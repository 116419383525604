import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dimmer, Form, Header, Icon, Loader, Message, Modal, Segment, Table } from "semantic-ui-react";
import { GET } from "../../utils/HttpClient";
import { defaultInterfaceTransactionDetail } from "../../constance/dataTypes";
import * as _ from "lodash";
import { formatComma } from "../../utils/stringFormat";


export default function InterfaceAXTransactionModal(props) {
  const { 
    open,
    onClose,
    onInterfaceAX,
    url,
    target,
  } = props;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [detail, setDetail] = React.useState(defaultInterfaceTransactionDetail);

  const fetchData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${url}${target}/ref-history/`)
      setDetail({ ...defaultInterfaceTransactionDetail, ...response.data })
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  const renderEmpty = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Header as='h2' icon textAlign='center'>
          <Icon name='file outline' circular/>
          <Header.Content>
            {t('interface_ax_history.empty')}
            <Header.Subheader>
              {t('interface_ax_history.empty_description')}
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Button
          primary
          content={t('do_interface_ax')}
          onClick={() => {
            onClose()
            onInterfaceAX(target)
          }}/>
      </div>
    )
  }

  const renderRequestBody = () => {
    const requestBody = _.get(detail, 'request_body', '')
    return (
      <Segment>
        <Table basic='very'>
          { !_.get(requestBody, 'list', []) && '-' }
          { _.get(requestBody, 'list', []).map(data => {
            let inventBody = {}
            if (_.get(data, 'InventDim', false)) {
              inventBody = _.get(data, 'InventDim', {})
            }
            if (_.get(data, 'InventDimDC', false)) {
              inventBody = _.get(data, 'InventDimDC', {})
            }
            const qtyKey = Object.keys(data).find(key => key.includes('Qty') && !key.includes('Pcs'))
            const qty = _.get(data, qtyKey, 0)
            return (
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>{_.get(data, 'ItemId', '-')}</Table.Cell>
                <Table.Cell>
                  <span style={{ fontWeight: 'bold' }}>WareHouse : </span>
                  {_.get(inventBody, 'inventLocationId', '-')}
                </Table.Cell>
                <Table.Cell>
                  <span style={{ fontWeight: 'bold' }}>Location : </span>
                  {_.get(inventBody, 'wMSLocationId', '-')}
                </Table.Cell>
                <Table.Cell>
                  <span style={{ fontWeight: 'bold' }}>Batch : </span>
                  {_.get(inventBody, 'inventBatchId', '-')}
                </Table.Cell>
                <Table.Cell>
                  <span style={{ fontWeight: 'bold' }}>QTY : </span>
                  {formatComma(qty)} {_.get(data, 'purchUnit', '')}
                </Table.Cell>
              </Table.Row>
            )
          }) }
        </Table>
      </Segment>
    )
  }

  const renderContent = () => {
    if (!isLoading && !detail.id) {
      return renderEmpty()
    }
    return (
      <Form>
        <Form.Group>
          <Form.Input
            width={6}
            fluid
            label={t('date')}
            transparent
            readOnly
            value={new Date(detail.created).toLocaleString('en-GB')}/>
          <Form.Input
            width={6}
            fluid
            label={t('interface_ax_history.url')}
            transparent
            readOnly
            value={detail.url}/>
        </Form.Group>
        <Form.TextArea style={{ minHeight: '150px' }} label={t('interface_ax_history.raw_data')} value={JSON.stringify(detail.request_body, null, 4)}/>
        <Form.Field label={t('interface_ax_history.data')}/>
        { renderRequestBody() }
        <Form.TextArea style={{ minHeight: '150px' }} label={t('interface_ax_history.result')} value={detail.response_text}/>
      </Form>
    )
  }

  React.useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open])

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        centered={false}
        closeIcon
      >
        <Modal.Header><Icon name='file alternate outline'/> {t('interface_ax_history.title')}</Modal.Header>
        <Modal.Content>
          <Dimmer inverted active={isLoading}>
            <Loader inverted>{t('loading')}</Loader>
          </Dimmer>
          {errorMessages && <Message error>{errorMessages}</Message>}
          {renderContent()}
        </Modal.Content>
        <Modal.Actions>
          <Button icon='cancel' content={t('close')} onClick={onClose}/>
        </Modal.Actions>
      </Modal>
    </div>
  )
}