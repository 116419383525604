import React from 'react';
import propTypes from 'prop-types';
import { Button, Form, Grid, Image, Message, Segment } from 'semantic-ui-react';
import logo_text from "../../assets/logo_text.png";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { POST } from "../../utils/HttpClient";
import { URL_AUTH } from "../../constance/urls";
import { useTranslation } from 'react-i18next';
import useLanguage from '../../hooks/useLanguage';
import { useUserContext } from '../../hooks/UserSettingProvider';
import { useLocation } from 'react-router-dom';


function LoginSegment (props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(false);
  const [toggleShowPassword, setToggleShowPassword] = React.useState(false);
	const { t } = useTranslation();
	const { language, setLanguage } = useLanguage();
	const userContext = useUserContext();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      username: Yup.string().required('required'),
      password: Yup.string().required('required'),
    }),
    initialValues: {
      username: '',
      password: '',
    },
		onSubmit: (values) => onLogin(values),
  });

	const onLogin = async (data) => {
    setIsLoading(true);
		setErrorMessages(null);
		let url = URL_AUTH.API_TOKEN_AUTH;
		if (props.liff) {
			url = URL_AUTH.LIFF_API_TOKEN_AUTH;
			data = {
				...data,
				liff_access_token: props.liff.getAccessToken(),
			}
		}
    try {
      const response = await POST(url, data);
			userContext.onLogin(response.data)
      // Callback after logged-in
			props.onLoggedIn(searchParams.get('redirect'));
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }
	return (
		<div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
					height: 100%;
        }
				`}
      </style>
			<div style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
				<Button.Group basic>
					<Button compact tabindex='-1' active={language === 'th'} content='TH' onClick={() => setLanguage('th')}/>
					<Button compact tabindex='-1' active={language === 'en'} content='EN' onClick={() => setLanguage('en')}/>
				</Button.Group>
			</div>
			<Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
				<Grid.Column style={{ maxWidth: "400px" }}>
					<Image src={logo_text} size='small' centered/>
					<Form size='large'>
						<Segment>
							{ errorMessages &&
								<Message negative>{errorMessages}</Message>
							}
							<Form.Input
								name='username'
								placeholder={t('user_profiles.username')}
								icon='user'
								iconPosition='left'
								values={formik.values.username}
								error={formik.errors.username}
								onChange={formik.handleChange}
							/>
							<Form.Input
                name='password'
                icon='lock'
                iconPosition='left'
								placeholder={t('user_profiles.password')}
                action={
                  <Button
                    basic
                    type='button'
                    icon={toggleShowPassword ? 'eye' : 'eye slash'}
                    onClick={() => setToggleShowPassword(prevState => !prevState)}/>
                }
                actionPosition='right'
                type={toggleShowPassword ? 'text' : 'password'}
                value={formik.values.password}
                error={formik.errors.password}
								onChange={formik.handleChange}
							/>
							<Button
								fluid
								content={t('menus.log_in')}
								color='green'
								icon='sign-in'
								size='large'
								loading={isLoading}
								onClick={formik.handleSubmit}
							/>
						</Segment>
					</Form>
				</Grid.Column>
			</Grid>
		</div>
	)
}

LoginSegment.defaultProps = {
	liff: null,
	onLoggedIn: () => {},
}

LoginSegment.propTypes = {
	liff: propTypes.object,
	onLoggedIn: propTypes.func,
}

export default React.memo(LoginSegment);