export const FONT_NORMAL_ANDROID = "%NORMAL%"
export const FONT_LARGE_ANDROID = "%LARGE%"
export const FONT_MEDIUM_ANDROID = "%MEDIUM%"
export const FONT_SMALL_ANDROID = "%SMALL%"
export const ALIGN_CENTER_ANDROID = "%CENTER%"
export const ALIGN_LEFT_ANDROID = "%LEFT%"
export const ALIGN_RIGHT_ANDROID = "%RIGHT%"


const countThaiStringLength = (text) => {
  if (typeof Intl.Segmenter === "function") {
    const segmenter = new Intl.Segmenter("th", { granularity: "grapheme" });
    const segments = segmenter.segment(text);
    return Array.from(segments).length;
  } else {
      // Fallback if Intl.Segmenter is not supported
      console.warn("Intl.Segmenter is not supported in this environment.");
      return text.length; // This might be inaccurate for Thai strings
  }
}

export const centerText = (text, size) => {
  if (typeof text == null || typeof text == undefined) {
    return text
  }
  text = text.toString()
  const textLength = countThaiStringLength(text)

  if (size < countThaiStringLength(text)) {
    return text
  }
  let result = ''
  const extraLength = text.length - textLength
  for (let i = 0; i < ((size - textLength) / 2); i ++) {
    result += ' '
  }
  result += text
  while (result.length < size) {
    result += ' '
  }
  for (let i =0; i < extraLength; i++) {
    result += ' '
  }
  return result
}