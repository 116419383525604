import React from "react";
import { GET } from "../../../utils/HttpClient";
import { Badge, Button, Card, Col, Collapse, Descriptions, Empty, Flex, Form, Input, Pagination, Row, Select, Skeleton, theme } from "antd";
import * as _ from "lodash";
import ErrorMessage from "./ErrorMessage";
import { useTranslation } from "react-i18next";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { PRIMARY_COLOR } from "../../../constance/color";
import { formatComma } from "../../../utils/stringFormat";
import propTypes from "prop-types";
import CustomDatePicker from "./CustomDatePicker";


const MAX_PAGE_SIZE = 5

const MobileCard = (props) => {
  const {
    content,
    column,
    data,
    onClick,
  } = props;

  const { token: { colorPrimary } } = theme.useToken()

  const descriptionItem = content.map((item, index) => ({
    key: 'content-' + index,
    label: <span>{item.icon}{item.label}</span>,
    children: _.get(item, 'render', null) ? item.render(data) : <span>{_.get(data, item.key)}</span>,
    span: item.span,
  }))

  const cardStyle = {
    border: '1px solid #f1f1f1',
    borderRadius: 'auto',
    position: 'relative',
    zIndex: 0,
    overflow: 'hidden',
  }

  const footerStyle = {
    content: '',
    position: 'absolute',
    zIndex: -1,
    bottom: '-15px',
    right: '0px',
    height: '25px',
    width: '100%',
    backgroundColor: colorPrimary,
  }

  return (
    <Card bordered={false} style={cardStyle} hoverable onClick={() => onClick(data)}>
      <Descriptions column={column} items={descriptionItem}/>
      <footer style={footerStyle}/>
    </Card>
  )
}

const MobileList = React.forwardRef((props, ref) => {
  const {
    fetchUrl,
    contentColumn,
    content,
    defaultShowFilter,
    filters,
    onClick,
  } = { ...defaultProps, ...props };

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [datas, setData] = React.useState([])
  const [total, setTotal] = React.useState(1)
  const [current, setCurrent] = React.useState(1)
  const [loading, setLoading] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [searchCount, setSearchCount] = React.useState(0)

  const fetchData = async (params={}) => {
    setLoading(true)
    setErrorMessages(null)
    setData([])
    try {
      const response = await GET(fetchUrl, { page_size: MAX_PAGE_SIZE, ...params })
      setData(_.get(response, 'data.results', []))
      setTotal(_.get(response, 'data.total', []))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = (values) => {
    setCurrent(1)
    setSearchCount(Object.values(values).filter(value => value).length)
    fetchData(values)
  }

  const handleClearFilter = () => {
    form.resetFields()
    setSearchCount(0)
    fetchData()
  }

  const handleChangePage = async (page) => {
    setCurrent(page)
    const values = await form.validateFields()
    fetchData({ ...values, page })
  }

  const filterItems = [{
    key: '1',
    label: (
      <Badge count={searchCount} offset={[16]} color={PRIMARY_COLOR}>
        {t('filter')}
      </Badge>
    ),
    children: (
      <Form form={form} onFinish={handleFilter}>
        {filters.map(({key, label, filterDate, filterOptions, options=[]}) => {
          // date filter
          if (filterDate) {
            return (
              <Form.Item name={key} label={label}>
                <CustomDatePicker/>
              </Form.Item>
            )
          }
          // options
          if (filterOptions) {
            return (
              <Form.Item key={key} name={key} label={label}>
                <Select placeholder={`${t('select')} ${label}`} options={options}/>
              </Form.Item>
            )
          }
          // default text search
          return (
            <Form.Item key={key} name={key} label={label}>
              <Input placeholder={`${t('search')} ${label}`} allowClear/>
            </Form.Item>
          )
        })}
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item>
              <Button
                block
                type='primary'
                htmlType='submit'
                icon={<SearchOutlined/>}
              >
                {t('search')}
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button block icon={<ClearOutlined/>} onClick={handleClearFilter}>{t('reset')}</Button>
          </Col>
        </Row>
      </Form>
    )
  }]

  React.useImperativeHandle(ref, () => ({
    fetchData: fetchData
  }))

  React.useEffect(() => {
    fetchData();
  }, [])
  
  return (
    <div>
      <Collapse 
        ghost
        defaultActiveKey={defaultShowFilter ? '1' : null}
        items={filterItems}/>
      <ErrorMessage message={errorMessages}/>
      <Flex vertical gap='middle'>
        <Skeleton loading={loading} avatar paragraph={{ rows: 4 }}/>
        { !loading && !_.get(datas, 'length', 0) && !errorMessages && (
          <Empty />
        ) }
        {datas.map(data => (
          <MobileCard key={data.id} content={content} column={contentColumn} data={data} onClick={onClick}/>
        ))}
        <Pagination
          disabled={loading}
          align='center'
          showSizeChanger={false}
          total={total}
          current={current}
          pageSize={MAX_PAGE_SIZE}
          itemRender={(page, type, originalElement) => {
            if (type == 'page') {
              return <a>{formatComma(page)}</a>
            }
            return originalElement
          }}
          onChange={(page) => {
            handleChangePage(page)
          }}/>
      </Flex>
    </div>
  )
})

const defaultProps = {
  fetchUrl: '',
  contentColumn: undefined,
  content: [],
  defaultShowFilter: false,
  filters: [],
  onClick: () => null,
}

MobileList.propTypes = {
  fetchUrl: propTypes.string,
  content: propTypes.array,
  contentColumn: propTypes.number,
  defaultShowFilter: propTypes.bool,
  filters: propTypes.arrayOf(
    propTypes.shape({
      key: propTypes.string,
      label: propTypes.string,
      filterDate: propTypes.bool,
      filterOptions: propTypes.bool,
      options: propTypes.array
    })
  ),
  onClick: propTypes.func,
}

export default MobileList;
