import { Form, InputNumber, Table } from "antd";
import propTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import { QC_WASTE_WOOD_CHOICES } from "../../constance/dataTypes";


export default function QCLogWoodWasteWoodTableForm (props) {
  const {
    form,
    style,
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const [total, setTotal] = React.useState(0);

  const calculateTotal = () => {
    const totalAmount = Object.values(QC_WASTE_WOOD_CHOICES)
      .reduce((prev, choice) => {
      return prev + (Number(form.getFieldValue(`amount_${choice}`)) || 0)
    }, Number(0))
    setTotal(totalAmount)
  }

  return (
    <Table
      style={style}
      bordered
      pagination={false}
      columns={[
        {
          dataIndex: 'label',
          title: t('log_wood_qcs.qc_waste_woods')
        },
        {
          title: t('barcodes.amount'),
          render: ({ key }) => (
            <Form.Item name={`amount_${key}`} style={{ margin: 0 }}>
              <InputNumber placeholder='0' variant='borderless' style={{ width: '100%' }} onChange={calculateTotal}/>
            </Form.Item>
          )
        }
      ]}
      dataSource={Object.values(QC_WASTE_WOOD_CHOICES).map(choice => ({
        key: choice, label: t(`qc_waste_woods.${choice}`),
      }))}
      summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>{t('datas.sum')}</Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              <InputNumber
                style={{ width: '100%' }}
                variant='borderless'
                placeholder='0'
                readOnly
                value={total}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
            </Table.Summary.Cell>
          </Table.Summary.Row>
      )}/>
  )
}

const defaultProps = {
  form: null,
  style: {},
}

QCLogWoodWasteWoodTableForm.propTypes = {
  form: propTypes.any.isRequired,
  style: propTypes.object
}