import React from "react";
import TSTable from "../components/common/TSTable";


export default function TableView(props) {
  return (
    <TSTable
     columns={[
      {
        title: 'code',
        dataIndex: 'code',
        key: 'code',
        sorter: true,
        searcher: true,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        searcher: true,
      },
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        sorter: true,
        searcher: true,
      },
    ]}
    data={[
      { code: 'A-01', name: 'John', age: 15 },
      { code: 'B-02', name: 'Jane', age: 20 },
      { code: 'X-93', name: 'Adam', age: 32 },
      { code: 'Z-54', name: 'Bob', age: 9 },
    ]}/>
  )
}