import { useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from '../routes/Routes';
import LoginSegment from "../components/common/LoginSegment";


function LoginView (props) {
  const history = useHistory();

  const handleOnLoggedIn = (path=null) => {
    if (path) {
      history.replace(path);
    } else {
      history.replace(PATH.LOG_WOOD_PO);
    }
  };

  return (
    <LoginSegment onLoggedIn={handleOnLoggedIn}/>
  );
}

export default LoginView;