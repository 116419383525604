import { Flex, Form, Layout, Select, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MainHeader from "../../components/common/MainHeader";
import Container from "../../components/common/antd/Container";
import useAntDropdownItem from "../../hooks/useAntDropdownItem";
import { URL_REPORT, URL_SCALE_LARGE } from "../../constance/urls";
import * as _ from "lodash";
import ExportReportButton from "../../components/common/antd/ExportReportButton";
import SearchSelect from "../../components/common/antd/SearchSelect";
import CustomDatePicker from "../../components/common/antd/CustomDatePicker";
import { QC_RAW_WOOD_TYPES, WOOD_GRADE_CHOICES } from "../../constance/dataTypes";


export default function QCRawWoodOnTableEmployeeReportView () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const date = Form.useWatch('date', form)
  const type = Form.useWatch('type', form)
  const truckCompanyCode = Form.useWatch('truck_company_code', form)
  const grade = Form.useWatch('grade', form)

  return (
    <Layout>
      <Layout.Header>
        <MainHeader/>
      </Layout.Header>
      <Layout.Content>
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.raw_wood_on_table_employee')}</Typography.Title>
          <Form form={form} layout='horizontal' style={{ marginTop: '3rem' }} labelCol={{ span: 3, offset: 3 }}>
            <Form.Item name='date' label={t('date')} rules={[{ required: true }]}>
              <CustomDatePicker range/>
            </Form.Item>
            <Form.Item name='type' label={t('qc_raw_woods.type')}>
              <Select
                options={Object.values(QC_RAW_WOOD_TYPES).map(type => ({
                  value: type, label: t(`qc_raw_woods.types.${type}`)
                }))}
                labelRender='text'
                placeholder={`${t('select')} ${t('qc_raw_woods.type')}`}/>
            </Form.Item>
            <Form.Item name='truck_company_code' label={t('truck_transactions.company')}>
              <SearchSelect
                url={URL_SCALE_LARGE.TRUCK_COMPANY}
                pagination={false}
                optionValue='code'
                labelRender={(data) => `[${data.code}] ${data.name}`}/>
            </Form.Item>
            <Form.Item name='grade' label={t('wood_imports.grade')}>
              <Select
                options={WOOD_GRADE_CHOICES}
                labelRender='text'
                placeholder={`${t('select')} ${t('wood_imports.grade')}`}/>
            </Form.Item>
          </Form>
          <Flex gap='middle' justify='center' style={{ marginTop: '2rem' }}>
            <ExportReportButton
              url={URL_REPORT.QC_RAW_WOOD_TRANSACTION_ON_TABLE_EMPLOYEE_REPORT}
              params={{
                date_after: _.get(date, 0, null),
                date_before: _.get(date, 1, null),
                truck_company_code: truckCompanyCode,
                grade, type
              }}
              validate={form.validateFields}/>
          </Flex>
        </Container>
      </Layout.Content>
    </Layout>
  )
}