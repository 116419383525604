import Cookies from 'js-cookie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Header, Icon, List } from 'semantic-ui-react';
import ChangePasswordModal from '../components/common/ChangePasswordModal';
import MainHeader from '../components/common/MainHeader';

const ProfileListItem = ({ icon, title, data }) => {
  return (
    <List.Item style={{ paddingTop: '2rem' }}>
      <List.Content floated='right'>{data}</List.Content>
      {icon && <Icon name={icon} />}
      <List.Content>
        <Header as='h4'>{title}</Header>
      </List.Content>
    </List.Item>
  )
}


export default function UserProfileView() {

  const { t } = useTranslation();
  const [user] = React.useState(JSON.parse(Cookies.get('user', {})));
  const [isChangePasswordOpen, setIsChangePasswordOpen] = React.useState(false);

  return (
    <div>
      <MainHeader />
      <Container style={{ minWidth: '300px', width: '600px', marginTop: '7em' }} textAlign='left'>
        <Header textAlign='center' as='h2'>{t('menus.user_profile')}</Header>
        <List divided verticalAlign='middle'>
          <ProfileListItem
            icon='user'
            title={t('user_profiles.username')}
            data={user.username} />
          <ProfileListItem
            icon='pencil alternate'
            title={t('user_profiles.full_name')}
            data={user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : '-'} />
          <ProfileListItem
            icon='address card outline'
            title={t('user_profiles.groups')}
            data={user.groups.length ? user.groups.toString() : '-'} />
          <ProfileListItem
            data={(
              <Button
                basic
                color='blue'
                icon='lock'
                content={t('user_profiles.change_password')}
                onClick={() => setIsChangePasswordOpen(true)} />
            )} />
        </List>
      </Container>

      <ChangePasswordModal
        open={isChangePasswordOpen}
        onClose={() => setIsChangePasswordOpen(false)} />
    </div>
  )
}
