import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Flex, message, Modal, theme } from "antd";
import propTypes from "prop-types";
import React from "react";
import ColorButton from "../ColorButton";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/lottie_report.json"
import { GET, POST } from "../../../utils/HttpClient";


const EXPORT_TYPES = {
  EXCEL: 'excel',
  PDF: 'pdf'
}


export default function ExportReportButton (props) {
  const {
    style,
    // button props
    size,
    justify,
    // fetch url
    url,
    method,
    // params
    params,
    validate,
    // export type 
    exportTypes,
    typeAsParams,
  } = { ...defaultProps, ...props};

  const { token: { colorError, colorSuccess } } = theme.useToken();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(false);

  const EXPORT_TYPE_RENDER_MAPPER = {
    [EXPORT_TYPES.PDF]: { icon: <FilePdfOutlined/>, text: 'PDF', color: colorError },
    [EXPORT_TYPES.EXCEL]: { icon: <FileExcelOutlined/>, text: 'Excel', color: colorSuccess },
  }

  const getFetchUrl = (exportType) => {
    return typeAsParams ? url : `${url}${exportType}/`
  }

  const getFetchMethod = () => {
    return method == 'post' ? POST : GET
  }

  const handleExportReport = async (exportType) => {
    if (loading) {
      return 
    }

    try {
      if (validate) {
        await validate();
      }
    } catch {
      return
    }

    setLoading(true)
    try {
      const response = await getFetchMethod()(getFetchUrl(exportType), params, false, true);
      setData(response.data);
    } catch (error) {
      messageApi.error({ content: `${t('error')} (${error.errorMessages})` });
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (data && loading) {
      window.open(URL.createObjectURL(data));
      setData(null);
      setLoading(false);
    }
  }, [data, loading])

  return (
    <div style={style}>
      {contextHolder}
      <Modal open={loading} maskClosable={false} title={t('loading')} onCancel={() => setLoading(false)} footer={[]}>
        <Lottie animationData={loadingAnimation} loop/>
      </Modal>
      <Flex gap='middle' justify={justify}>
        {exportTypes.map(exportType => (
          <ColorButton
            type='primary'
            size={size}
            override={_.get(EXPORT_TYPE_RENDER_MAPPER, `${exportType}.color`)}
            icon={_.get(EXPORT_TYPE_RENDER_MAPPER, `${exportType}.icon`)}
            onClick={() => handleExportReport(exportType)}
          >
            {_.get(EXPORT_TYPE_RENDER_MAPPER, `${exportType}.text`, '')}
          </ColorButton>
        ))}
      </Flex>
    </div>
  )
}


const defaultProps = {
  style: {},
  url: '',
  size: null,
  justify: null,
  exportTypes: [EXPORT_TYPES.PDF, EXPORT_TYPES.EXCEL],
  typeAsParams: false,
  method: 'post',
  params: {},
  validate: () => null,
}


ExportReportButton.propTypes = {
  url: propTypes.string,
  size: propTypes.string,
  justify: propTypes.string,
  exportTypes: propTypes.arrayOf(propTypes.string),
  typeAsParams: propTypes.bool,
  method: propTypes.string,
  params: propTypes.object,
  validate: () => null,
  style: propTypes.object,
}