import { DatePicker } from "antd";
import React from "react";
import propTypes from "prop-types";
import dayjs from "dayjs";
import { DATE_FILTER_FORMAT } from "../../../constance/format";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { HUMAN_DATE_FORMAT } from "../../../constance/strings";


export default function CustomDatePicker (props) {
  const {
    style,
    range,
    placeholder,
    // controlled props don't pass these at any cost
    value,
    onChange,
    ...datePickerProps
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const customStyle = { width: '100%', ...style };
  const [renderValue, setRenderValue] = React.useState(null);

  const handleChange = (value) => {
    if (!value) {
      return null
    }
    onChange(range ? 
      [_.get(value, 0, null).format(DATE_FILTER_FORMAT), _.get(value, 1, null).format(DATE_FILTER_FORMAT)] : 
      value.format(DATE_FILTER_FORMAT)
    )
  }

  React.useEffect(() => {
    if (value) {
      setRenderValue(range ? 
        [dayjs(_.get(value, 0, null)), dayjs(_.get(value, 1, null))] :
        dayjs(value)
      )
    } else {
      setRenderValue(null)
    }
  }, [value])

  return range ? (
    <DatePicker.RangePicker
      style={customStyle}
      placeholder={placeholder ? placeholder : [`${t('select')} ${t('date')}`, `${t('select')} ${t('date')}`]}
      format={HUMAN_DATE_FORMAT}
      value={renderValue}
      onChange={handleChange}
      {...datePickerProps}/>
  ) : (
    <DatePicker
      style={customStyle}
      placeholder={placeholder ? placeholder : `${t('select')} ${t('date')}`}
      format={HUMAN_DATE_FORMAT}
      value={renderValue}
      onChange={handleChange}
      {...datePickerProps}/>
  )
}

const defaultProps = {
  style: {},
  range: false,
  placeholder: null,
}

CustomDatePicker.propTypes = {
  style: propTypes.object,
  range: propTypes.bool,
  placeholder: propTypes.string,
}