import { RightOutlined } from "@ant-design/icons";
import { Button, Flex, Typography } from "antd";
import propTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { QC_RAW_WOOD_TYPES } from "../../constance/dataTypes";


export default function SelectQCRawWoodType(props=defaultProps) {
  const { 
    onSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Typography.Title level={5} type='secondary'>{t('qc_raw_woods.select_type')}</Typography.Title>
      <Flex vertical gap='middle'>
        { Object.values(QC_RAW_WOOD_TYPES).map(type => (
          <Button color='primary' variant='outlined' icon={<RightOutlined/>} onClick={() => onSubmit(type)}>
            {t(`qc_raw_woods.types.${type}`)}
          </Button>
        )) }
      </Flex>
    </div>
  )
}

const defaultProps = {
  onSubmit: () => null,
}

SelectQCRawWoodType.propTypes = {
  onSubmit: propTypes.func,
}