import React from "react";
import { useTranslation } from "react-i18next";
import { URL_LOG_WOOD, URL_QC } from "../../constance/urls";
import MobileList from "../../components/common/antd/MobileList";
import * as _ from "lodash";
import { theme } from "antd";
import { useHistory } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import useAntDropdownItem from "../../hooks/useAntDropdownItem";


export default function QCLogWoodView () {

  const history = useHistory();
  const { t } = useTranslation();
  const { token: { colorSuccess, colorInfo } } = theme.useToken();

  const content = [
    { 
      key: 'guageNumber',
      label: t('log_wood_qcs.guage_number'), 
    },
    { 
      key: 'logWoodType',
      label: t('log_wood_qcs.log_wood_type'), 
      render: (item) => <span>{_.get(item, 'logWoodType.name', '')}</span>,
    },
    { 
      key: 'courtyard',
      label: t('log_wood_qcs.courtyard'), 
      render: (item) => <span>{_.get(item, 'courtyard.name', '')}</span>,
    },
    { 
      key: 'id',
      label: t('log_wood_qcs.id'),
    },
    {
      key: 'logWoodEvalSize',
      label: t('log_wood_qcs.log_wood_eval_size'),
      render: (item) => <span>{_.get(item, 'logWoodEvalSize.name', '')}</span>,
    },
    {
      key: 'amount',
      label: t('datas.amount'),
      render: (item) => (
        <span>{
          _.get(item, 'qcDiameterList.0.amount', 0) + 
          _.get(item, 'qcDiameterList.0.amount_5_1', 0) + 
          _.get(item, 'qcDiameterList.0.amount_5_2', 0)
        } {t('units.wood')}</span>
      ),
    },
    {
      key: 'qcUser',
      label: t('log_wood_qcs.qc_user'),
      render: (item) => <span>{_.get(item, 'qcUser.firstname', '')} {_.get(item, 'qcUser.lastname', '')}</span>,
      span: 2,
    },
    {
      key: 'status',
      label: t('log_wood_qcs.status'),
      render: (item) => (_.get(item, 'is_checked', false) ? 
        <span style={{ color: colorSuccess }}>{t('log_wood_qcs.statuses.checked')}</span> : 
        <span style={{ color: colorInfo }}>{t('log_wood_qcs.statuses.not_checked')}</span>
      ),
    },
  ]

  const courtyardChoices = useAntDropdownItem({ url: URL_LOG_WOOD.COURTYARD })

  return (
    <div>
      <MobileList
        fetchUrl={URL_QC.LOG_WOOD_QC}
        content={content}
        contentColumn={2}
        defaultShowFilter
        filters={[
          { key: 'date', label: t('date'), filterDate: true },
          { key: 'courtyard', label: t('log_wood_qcs.courtyard'), filterOptions: true, options: courtyardChoices.options },
          { key: 'qc_id', label: t('log_wood_qcs.id') },
        ]}
        onClick={(item) => history.push(`${PATH.QC_LOG_WOOD}${item.id}/`)}/>
    </div>
  )
}