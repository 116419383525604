export const defaultRawLogWoodQC = {
  id: null,
  batchId: '',
  buyDate: '',
  buyPriceSmallScale: 0,
  catch_yield: false,
  courtyard: {
    id: null,
    code: '',
    name: '',
  },
  customer: null,
  cutWeight: 0,
  guageNumber: '',
  isInterfaced: false,
  isInterfacedSM: false,
  locationCode: '',
  logWoodEvalLength: null,
  logWoodEvalSize: {
    id: null,
    code: '',
    costStd: 0,
    name: '',
    sellPrice: 0,
    tradeStd: 0,
    usageStd: 0,
    weightStd: 0
  },
  logWoodType: {
    id: null,
    code: '',
    name: '',
    nameEng: '',
  },
  logWoodWage: null,
  productionFormular: '',
  qcDateTime: '',
  qcDiameterList: [
    {
      id: null,
      amount: 0,
      amount_5_1: 0,
      amount_5_2: 0,
      name: '',
    }
  ],
  qcUser: {
    id: null,
    firstname: '',
    lastname: '',
  },
  rawWeight: 0,
  sawName: null,
  sawTable: 0,
  sawTime: null,
  scaleDateTime: null,
  scaleId: null,
  sort_type: 0,
  sumAmount: 0,
  transactionType: {
    id: 4,
    code: '',
    name: '',
    name_en: '',
  },
  wageStatus: false,
  wasteWoodList: [],
  weight: 0,
  woodPlot: null,
  woodSource: null,
  wsCustomer: null,
  yield_customer: null,
  is_inspected: false
}

export const QC_RAW_WOOD_TYPES = {
  NORMAL: 'normal',
  SAW_DEFECT: 'saw_defect',
  BUY_DEFECT: 'buy_defect',
  DRY_DEFECT: 'dry_defect',
}


export const QC_RAW_WOOD_SETTING_TYPES = {
  QUALITY_LEVEL: 'quality_level',
  QUALITY_PROBLEM: 'quality_problem',
  SAW_PROBLEM: 'saw_problem',
}


export const defaultQCLogWoodQC = {
  id: null,
  employee_code: '',
  employee_name: '',
  guage_number: '',
  log_wood_eval_size: '',
  log_wood_eval_length: '',
  saw_time: '',
  sum_amount: 0,
  created_by_code: '',
  created_by_name: '',
  created: '',
  updated: '',
  is_active: true,
  amount_no_1: 0,
  amount_no_2: 0,
  amount_no_3: 0,
  amount_no_4: 0,
  amount_no_5_1a: 0,
  amount_no_5_1b: 0,
  amount_no_5_2: 0,
  amount_cracked: 0,
  amount_cracked_log: 0,
  log_wood_qc: null,
  qc_id: null,
}

export const getSortTypeRender = (type) => {
  switch(type) {
    case 1: return 'log_wood_qcs.sort_types.one_per_gauge'
    case 2: return 'log_wood_qcs.sort_types.many_per_gauge'
    case 3: return 'log_wood_qcs.sort_types.carpet_type'
    case 4: return 'log_wood_qcs.sort_types.whole_courtyard'
    default: return null
  }
}

export const EVAL_SIZE_CHOICES = {
  NO_1: 'no_1',
  NO_2: 'no_2',
  NO_3: 'no_3',
  NO_4: 'no_4',
  NO_5_1A: 'no_5_1a',
  NO_5_1B: 'no_5_1b',
  NO_5_2: 'no_5_2',
}

export const QC_WASTE_WOOD_CHOICES = {
  CRACKED: 'cracked',
  CRACKED_LOG: 'cracked_log',
}

export const defaultRawWoodQC = {
  id: null,
}