import React from "react";
import Cookies from 'js-cookie'
import { Route, Redirect } from "react-router-dom";
import { PATH } from "./Routes";


export default function PrivateRoute({component: Component, location, ...rest}) {
  const { pathname } = location

  const renderComponent = (props) => {
    if (Cookies.get('token') != null) {
      return <Component {...props} />
    }
    else {
      return (
        <Redirect
          to={{ 
            pathname: PATH.LOGIN, 
            search: new URLSearchParams({ redirect: pathname }).toString(),
            state: { from: props.location, redirect: pathname } 
          }}
        />
      )
    }
  };

  return <Route {...rest} render={renderComponent} />;
}
