import { Select, Spin } from "antd";
import propTypes from "prop-types";
import React from "react";
import { useDebouncedCallback } from "use-debounce";
import * as _ from "lodash";
import { GET } from "../../../utils/HttpClient";
import { LoadingOutlined } from "@ant-design/icons";


const SEARCH_DELAY_MILLISECONDS = 1000;

export default function SearchSelect (props) {
  const {
    url,
    params,
    pagination,
    optionValue,
    optionLabel,
    labelRender,
    manual,
    disabled,
    style,

    // Controlled props. don't passed this one
    value,
    ...selectProps
  } = { ...defaultProps, ...props };

  const debounceSearch = useDebouncedCallback(
    (search) => fetch({ search }),
    SEARCH_DELAY_MILLISECONDS,
  )
  const [datas, setDatas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const isValueId = optionValue == 'id'
  
  const checkIfExistInDatas = (value) => {
    const found = datas.find(data => _.get(data, optionValue, '') == value)
    return found ? true : false
  }

  const initialValue = async (value) => {
    setLoading(true);
    try {
      const response = await GET(isValueId ? `${url}${value}/` : url, isValueId ? {} : { [optionValue]: value })
      if (isValueId) {
        setDatas(prev => [...prev, _.get(response, 'data', {})]);
      } else {
        setDatas(prev => [...prev, _.get(response, pagination ? 'data.results.0' : 'data.0', {})]);
      }
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  const fetch = async (additionalParams) => {
    setLoading(true);
    try {
      const response = await GET(url, { ...params, ...additionalParams })
      setDatas(_.get(response, pagination ? 'data.results' : 'data', []))
    } catch (error) { } finally {
      setLoading(false);
    }
  }

  React.useState(() => {
    if (!manual) {
      fetch();
    }
  }, [manual])

  React.useEffect(() => {
    if (value && !checkIfExistInDatas(value)) {
      initialValue(value);
    }
  }, [value])

  return (
    <Select
      style={{ textAlign: 'left', ...style}}
      allowClear
      showSearch
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={debounceSearch}
      notFoundContent={loading ? <Spin indicator={<LoadingOutlined size='small'/>} /> : null}
      loading={loading} 
      disabled={disabled}
      options={(datas || []).map((data) => ({
        value: _.get(data, optionValue, null),
        label: labelRender ? labelRender(data) : _.get(data, optionLabel, null),
      }))}
      value={value}
      {...selectProps}
    />
  )
}

const defaultProps = {
  url: '',
  params: {},
  pagination: true,
  optionValue: 'id',
  optionLabel: 'name',
  labelRender: () => null,
  manual: false,
  disabled: false,
}

SearchSelect.propTypes = {
  url: propTypes.string,
  params: propTypes.object,
  pagination: propTypes.bool,
  optionValue: propTypes.string,
  keyLabel: propTypes.string,
  keyLabelRender: propTypes.func,
  manual: propTypes.bool,
  disabled: propTypes.bool,
}
