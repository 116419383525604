import { PrinterOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Flex, Form, message, Spin, theme } from "antd";
import propTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { POST, PUT } from "../../utils/HttpClient";
import { URL_QC } from "../../constance/urls";
import { ellipsisString } from "../../utils/Utils";
import * as _ from "lodash";
import ErrorMessage from "../common/antd/ErrorMessage";
import ColorButton from "../common/ColorButton";
import { FONT_NORMAL_ANDROID, ALIGN_CENTER_ANDROID, centerText } from '../../constance/printers';
import RawWoodQCDetailForm from "./RawWoodQCDetailForm";
import dayjs from "dayjs";
import { HUMAN_DATE_FORMAT } from "../../constance/strings";
import { QC_RAW_WOOD_TYPES } from "../../constance/dataTypes";


export default function InputRawWoodQCDetail (props=defaultProps) {
  const {
    type,
    data,
    onBack,
    onHome,
  } = props;

  const { t } = useTranslation();
  const { token: { colorInfo } } = theme.useToken();
  const detailRef = React.useRef();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [savedId, setSavedId] = React.useState(null);

  const onSubmit = async (values) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      let response = null
      if (savedId) {
        response = await PUT(`${URL_QC.QC_RAW_WOOD_TRANSACTION}${savedId}/`, values);
      } else {
        response = await POST(URL_QC.QC_RAW_WOOD_TRANSACTION, values);
      }
      messageApi.open({
        type: 'success',
        content: t('success'),
      });
      setSavedId(_.get(response, 'data.id', null))
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: ellipsisString(error.errorMessages, 100),
      });
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const onPrint = async () => {
    if (!savedId) {
      return 
    }
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await POST(`${URL_QC.QC_RAW_WOOD_TRANSACTION}${savedId}/print/`);
      doPrintAndriod(_.get(response, 'data', {}))
      messageApi.open({
        type: 'success',
        content: t('success'),
      });
    } catch (error) {
      console.log(error)
      messageApi.open({
        type: 'error',
        content: ellipsisString(error.errorMessages, 100),
      });
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const doPrintAndriod = (detail) => {
    if (!window.JSInterface || !detailRef.current) {
      return 
    }
    let datas = []

    datas.push(ALIGN_CENTER_ANDROID)
    datas.push(FONT_NORMAL_ANDROID)
    datas.push(`ประเภท : ${detail.type_detail}`)
    datas.push(`วันที่ผลิต : ${dayjs(detail.produce_date).format(HUMAN_DATE_FORMAT)}`)
    if (detail.type == QC_RAW_WOOD_TYPES.NORMAL) {
      datas.push(`กะการทำงาน : ${detail.saw_time_name}`)
      datas.push(`ชุดเลื่อย : ${detail.saw_name}`)
    } else {
      datas.push(`พนักงาน : ${detail.employee_detail}`)
      if (detail.truck_company_detail) {
        datas.push(`ลูกค้า : ${detail.truck_company_detail}`)
      }
    }

    Object.entries(detailRef.current.poppulate(_.get(detail, 'details', []))).forEach(([key, value]) => {
      datas.push('')
      datas.push(t(`qc_raw_woods.settings.${key}`))
      datas.push('--------------------------------------------------------------------')
      datas.push(`| ${centerText('ประเภท', 15)} | ${centerText('บ', 9)} | ${centerText('ก', 9)} | ${centerText('หน', 9)} | ${centerText('หล', 9)} |`)
      datas.push('----------------------------------------------------------------------')
      value.forEach(v => {
        datas.push(`| ${centerText(_.get(v, 'name', ''), 15)} ` +
          `| ${centerText(_.get(v, 'thin', 0), 9)} ` +
          `| ${centerText(_.get(v, 'medium', 0), 9)} ` +
          `| ${centerText(_.get(v, 'thick', 0), 9)} ` +
          `| ${centerText(_.get(v, 'edge', 0), 9)} ` +
          `|`)
      })
      datas.push('--------------------------------------------------------------------')
    })

    window.JSInterface.printAndroid(datas.join('\r\n'))
  }

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue(data)
    }
  }, [data])

  return (
    <div>
      {contextHolder}
      <ErrorMessage message={errorMessages}/>
      <Spin spinning={loading}>
        <Form form={form} onFinish={onSubmit} loading={loading} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ textAlign: 'left' }}>
          <Form.Item name='type' initialValue={type} hidden/>
          <Form.Item name='produce_date' hidden/>
          <Form.Item name='employee' hidden/>
          <Form.Item name='grade' hidden/>
          <Form.Item name='saw_time' hidden/>
          <Form.Item name='saw_name' hidden/>
          <Form.Item name='head_disect' hidden/>
          <Form.Item name='head_slice' hidden/>
          <Form.Item name='tail_disect' hidden/>
          <Form.Item name='tail_slice' hidden/>
          <Form.Item name='truck_company' hidden/>

          <RawWoodQCDetailForm ref={detailRef} form={form}/>

          <Flex justify='center' gap='middle'>
            <Button type='primary' icon={<SaveOutlined/>} loading={loading} htmlType='submit'>{t('save')}</Button>
            <Button onClick={() => savedId ? onHome() : onBack()} loading={loading}>{savedId ? t('home') : t('back')}</Button>
          </Flex>

          {savedId && (
            <Flex justify='center' style={{ marginTop: '2rem' }}>
              <ColorButton
                size='large'
                type='primary'
                override={colorInfo}
                icon={<PrinterOutlined/>}
                onClick={onPrint}
              >
                {t('print')}
              </ColorButton>
            </Flex>
          )}
        </Form>
      </Spin>
    </div>
  )
}

const defaultProps = {
  type: '',
  data: {},
  onBack: () => null,
  onHome: () => null,
}

InputRawWoodQCDetail.propTypes = {
  type: propTypes.string,
  data: propTypes.object,
  onBack: propTypes.func,
  onHome: propTypes.func,
}