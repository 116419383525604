import { Button, Descriptions, Flex, Form, InputNumber, Layout, message, Spin, Table, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { SaveOutlined } from "@ant-design/icons";
import { URL_QC } from "../../constance/urls";
import Container from "../../components/common/antd/Container";
import * as _ from "lodash";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import SubHeader from "../../components/common/SubHeader";
import { defaultQCLogWoodQC, EVAL_SIZE_CHOICES, getSortTypeRender, QC_WASTE_WOOD_CHOICES } from "../../constance/dataTypes";
import { GET, PATCH } from "../../utils/HttpClient";
import { HUMAN_DATETIME_FORMAT } from "../../constance/strings";
import ErrorMessage from "../../components/common/antd/ErrorMessage";
import QCLogWoodEvalSizeTableForm from "../../components/qc-log-wood/QCLogWoodEvalSizeTableForm";
import QCLogWoodWasteWoodTableForm from "../../components/qc-log-wood/QCLogWoodWasteWoodTableForm";


export default function QCLogWoodReportDetailView () {

  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [data, setData] = React.useState(defaultQCLogWoodQC)
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const amountCracked = Form.useWatch('amount_' + QC_WASTE_WOOD_CHOICES.CRACKED, form)
  const amountCrackedLog = Form.useWatch('amount_' + QC_WASTE_WOOD_CHOICES.CRACKED_LOG, form)

  const onBack = () => {
    history.push(PATH.QC_LOG_WOOD_REPORT)
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GET(`${URL_QC.QC_LOG_WOOD_QC_TRANSACTION}${id}/`)
      setData(response.data)
      form.setFieldsValue(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await PATCH(`${URL_QC.QC_LOG_WOOD_QC_TRANSACTION}${id}/`, values);
      messageApi.success({ content: t('success') })
    } catch (error) {
      setErrorMessages(error.errorMessages);
      messageApi.error({ content: error.errorMessages })
    } finally {
      setLoading(false);
    }
  }

  const getWasteWoodAll = () => {    
    return (amountCracked || 0) + (amountCrackedLog || 0)
  }

  React.useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id])

  return (
    <Layout>
      <Layout.Header>
        <SubHeader/>
      </Layout.Header>
      <Layout.Content>
        {contextHolder}
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.log_wood')}</Typography.Title>
          <ErrorMessage message={errorMessages}/>
          <Spin spinning={loading}>
            <Descriptions style={{ marginTop: '1rem' }} title={t('log_wood_qcs.title')} column={1} bordered items={[
              {
                key: 'created',
                label: t('log_wood_qcs.checks.date'),
                children: dayjs(_.get(data, 'created', '')).format(HUMAN_DATETIME_FORMAT)
              },
              {
                key: 'created_by',
                label: t('log_wood_qcs.checks.employee'),
                children: <span>[{_.get(data, 'created_by_code', '')}] {_.get(data, 'created_by_name', '')}</span>
              },
              {
                key: 'qc_id',
                label: t('log_wood_qcs.id'),
                children: _.get(data, 'qc_id', '-')
              },
              {
                key: 'log_wood_eval_size',
                label: t('log_wood_qcs.log_wood_eval_size'),
                children: _.get(data, 'log_wood_eval_size', '-')
              },
              {
                key: 'log_wood_eval_length',
                label: t('log_wood_qcs.log_wood_eval_length'),
                children: _.get(data, 'log_wood_eval_length', '-')
              },
              {
                key: 'guage_number',
                label: t('log_wood_qcs.guage_number'),
                children: _.get(data, 'guage_number', '-')
              },
              {
                key: 'saw_time',
                label: t('log_wood_qcs.saw_time'),
                children: _.get(data, 'saw_time', '-')
              },
              {
                key: 'qc_date',
                label: t('log_wood_qcs.qc_date'),
                children: dayjs(_.get(data, 'qc_date_time', '')).format(HUMAN_DATETIME_FORMAT)
              },
              {
                key: 'sort_type',
                label: t('log_wood_qcs.sort_type'),
                children: getSortTypeRender(_.get(data, 'sort_type', 0)) ?  t(getSortTypeRender(_.get(data, 'sort_type', 0))) : '-'
              },  
              {
                key: 'employee',
                label: t('log_wood_qcs.employee'),
                children: <span>[{_.get(data, 'employee_code', '')}] {_.get(data, 'employee_name', '')}</span>
              },
            ]}/>
          </Spin>

          <Form form={form} style={{ marginTop: '2rem' }} onFinish={handleSubmit}>
            <QCLogWoodEvalSizeTableForm
              form={form}
              style={{ marginTop: '1rem' }}/>

            <QCLogWoodWasteWoodTableForm
              form={form}
              style={{ marginTop: '1rem' }}/>
            <Flex justify='center' gap='middle' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <Button size='large' type='primary' icon={<SaveOutlined/>} loading={loading} htmlType='submit'>{t('save')}</Button>
              <Button size='large' onClick={() => onBack()} loading={loading}>{t('back')}</Button>
            </Flex>
          </Form>
        </Container>
      </Layout.Content>
    </Layout>
  )
}