import React from 'react';
import './App.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';
import './antd.css';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes/Routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table-6/react-table.css';
import { askForPermissioToReceiveNotifications } from './push-notification';
import { MediaContextProvider } from "./AppMedia";
import UserSettingProvider from './hooks/UserSettingProvider';
import { ConfigProvider } from 'antd';
import { PRIMARY_COLOR } from './constance/color';
import WindowDimensionProvider from './hooks/WindowDimensionProvider';


toast.configure();
function App() {

  React.useEffect(() => {
    if (!localStorage.getItem('notification-token')) {
      askForPermissioToReceiveNotifications();
    }
  }, []);

  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: PRIMARY_COLOR,
        colorBgLayout: 'white',
      },
    }}>
      <MediaContextProvider>
        <WindowDimensionProvider>
          <UserSettingProvider>
            <React.Suspense fallback={
              <Spin fullscreen tip={<span style={{ color: "black" }}>Loading...</span>}
                size='large'
                indicator={
                  <LoadingOutlined
                    style={{
                      color: "black",
                    }}
                    spin />
                }
              />
            }>
              <Router>
                <div className='App'>
                  <Routes />
                </div>
              </Router>
            </React.Suspense>
          </UserSettingProvider>
        </WindowDimensionProvider>
      </MediaContextProvider>
    </ConfigProvider>
  );
}

export default App;