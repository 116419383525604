import React from "react";
import useLanguage from "../hooks/useLanguage";
import { Button, Flex, Image, Radio } from "antd";
import { useTranslation } from "react-i18next";
import logo_text from "../assets/logo_text.png";
import { useHistory } from 'react-router-dom';
import { PATH } from "../routes/Routes";
import { RightOutlined } from "@ant-design/icons";


export default function HomeView() {
	const { t } = useTranslation();
	const { language, setLanguage } = useLanguage();
  const history = useHistory();

  const menus = [
    {
      label: t('main_menus.qrcode'),
      path: PATH.LOG_WOOD_PO,
    },
    {
      label: t('main_menus.qc'),
      path: PATH.QC_MAIN,
    }
  ]

  return (
		<div>
      <style>
        {`
          body > div,
          body > div > div,
          body > div > div > div.login-form {
				  	height: 100%;
          }
				`}
      </style>
			<div style={{ position: "absolute", top: "1rem", right: "1rem" }}>
        <Radio.Group value={language} onChange={e => setLanguage(e.target.value)}>
          <Radio.Button value="th">TH</Radio.Button>
          <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>
			</div>

      <div style={{ width: "100%", marginTop: "3rem", padding: "0 50px" }}>
        <Image src={logo_text} height={200}/>

        <Flex vertical gap="middle">
          {menus.map(menu => (
            <Button
              type="primary"
              block
              size="large"
              icon={<RightOutlined />}
              onClick={() => history.replace(menu.path)}
            >
              {menu.label}
            </Button>
          ))}
        </Flex>
      </div>
    </div>
  )
}