import React from "react";
import { Button, Container, Form, Header, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import EnterGroupDate from "../components/post-baking-waste-wood-count/EnterGroupDate";
import SelectOvenType from "../components/post-baking-waste-wood-count/SelectOvenType";
import CountWoodAmount from "../components/post-baking-waste-wood-count/CountWoodAmount";
import OvenSetMenu from "../components/post-baking-waste-wood-count/OvenSetMenu";
import SelectOvenSet from "../components/post-baking-waste-wood-count/SelectOvenSet";
import SelectOvenSetDetail from "../components/post-baking-waste-wood-count/SelectOvenSetDetail";
import OvenSetDetail from "../components/post-baking-waste-wood-count/OvenSetDetail";
import MainHeader from "../components/common/MainHeader";
import WoodSummary from "../components/post-baking-waste-wood-count/WoodSummary";
import { POST } from "../utils/HttpClient";
import { URL_POST_BAKING } from "../constance/urls";
import * as _ from "lodash";


export default function PostBakingWasteWoodCountView () {
  const POST_BAKING_WASTE_WOOD_COUNT_STEP = {
    ENTER_GROUP_DATE: 'enter_group_date',
    WOOD_SUMMARY: 'wood_summary',
    SELECT_OVEN_TYPE: 'select_oven_type',
    COUNT_WOOD_AMOUNT: 'count_wood_amount',
    OVEN_SET_MENU: 'oven_set_menu',
    SELECT_OVEN_SET: 'select_oven_set',
    SELECT_OVEN_SET_DETAIL: 'select_oven_set_detail',
    OVEN_SET_DETAIL: 'oven_set_detail',
  }

  const { t } = useTranslation();
  const [step, setStep] = React.useState(POST_BAKING_WASTE_WOOD_COUNT_STEP.ENTER_GROUP_DATE);
  const [groupDate, setGroupDate] = React.useState(null);
  const [ovensSelect, setOvensSelect] = React.useState(null);
  const [transaction, setTransaction] = React.useState(null);
  const [target, setTarget] = React.useState(null);
  const [warningMessage, setWarningMessge] = React.useState(null);

  const resetState = () => {
    setGroupDate(null)
    setOvensSelect(null)
    setTransaction(null)
    setTarget(null)
    setWarningMessge(null)
  }

  // ALL -> ENTER_GROUP_DATE
  const onHome = () => {
    resetState();
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.ENTER_GROUP_DATE)
  }

  // ENTER_GROUP_DATE -> SELECT_OVEN_TYPE
  const onEnterGroupDate = (date) => {
    setGroupDate(date)
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.WOOD_SUMMARY)
  }

  // WOOD_SUMMARY -> SELECT_OVEN_TYPE
  const onAddWood = () => {
    if (transaction) {
      setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.COUNT_WOOD_AMOUNT)
    } else {
      setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.SELECT_OVEN_TYPE)
    }
  }

  // WOOD_SUMMARY -> COUNT_WOOD_AMOUNT
  const onEditWood = (target) => {
    setTarget(target)
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.COUNT_WOOD_AMOUNT)
  }

  // SELECT_OVEN_TYPE -> COUNT_WOOD_AMOUNT
  const onSelectOven = (oven) => {
    setOvensSelect(oven)
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.COUNT_WOOD_AMOUNT)
  }

  // SELECT_OVEN_TYPE -> OVEN_SET_MENU
  const onSelectOvenSetMenu = () => {
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.OVEN_SET_MENU)
  }

  // OVEN_SET_MENU -> SELECT_OVEN_SET
  const onGoToSelectOvenSet = () => {
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.SELECT_OVEN_SET)
  }

  // OVEN_SET_MENU -> SELECT_OVEN_SET_DETAIL
  const onGoToSelectOvenSetDetail = () => {
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.SELECT_OVEN_SET_DETAIL)
  }

  // OVEN_SET_MENU -> SELECT_OVEN_TYPE
  const onBackToSelectOvenType = () => {
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.SELECT_OVEN_TYPE)
  }

  // SELECT_OVEN_SET, SELECT_OVEN_SET_DETAIL -> OVEN_SET_MENU
  const onBackToSelectOvenSetMenu = () => {
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.OVEN_SET_MENU)
  }

  // SELECT_OVEN_SET -> COUNT_WOOD_AMOUNT
  const onSelectOvenSet = (ovens) => {
    setOvensSelect(ovens)
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.COUNT_WOOD_AMOUNT)
  }

  // SELECT_OVEN_SET_DETAIL -> OVEN_SET_DETAIL
  const onGoToOvenSetDetail = (ovens) => {
    setOvensSelect(ovens)
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.OVEN_SET_DETAIL)
  }

  // OVEN_SET_DETAIL -> SELECT_OVEN_SET_DETAIL
  const onBackToSelectOvenSetDetail = () => {
    setOvensSelect([])
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.SELECT_OVEN_SET_DETAIL)
  }

  // COUNT_WOOD_AMOUNT -> WOOD_SUMMARY
  const onBackToSummary = (warningMessage) => {
    setTarget(null);
    setWarningMessge(warningMessage)
    setStep(POST_BAKING_WASTE_WOOD_COUNT_STEP.WOOD_SUMMARY)
  }

  const renderStep = () => {
    switch (step) {
      case POST_BAKING_WASTE_WOOD_COUNT_STEP.ENTER_GROUP_DATE: 
        return <EnterGroupDate onEnter={onEnterGroupDate}/>
      case POST_BAKING_WASTE_WOOD_COUNT_STEP.SELECT_OVEN_TYPE:
        return <SelectOvenType onSelectOven={onSelectOven} onSelectOvenSetMenu={onSelectOvenSetMenu} onBack={onHome}/>
      case POST_BAKING_WASTE_WOOD_COUNT_STEP.WOOD_SUMMARY:
        return <WoodSummary groupDate={groupDate} ovens={ovensSelect} transaction={transaction} warningMessage={warningMessage} onEdit={onEditWood} handleAddWood={onAddWood} onFinish={onHome} />
      case POST_BAKING_WASTE_WOOD_COUNT_STEP.COUNT_WOOD_AMOUNT:
        return <CountWoodAmount target={target} groupDate={groupDate} ovens={ovensSelect} getTransaction={getTransaction} onBack={onBackToSummary}/>
      case POST_BAKING_WASTE_WOOD_COUNT_STEP.OVEN_SET_MENU:
        return <OvenSetMenu onGoToSelectOvenSet={onGoToSelectOvenSet} onGoToSelectOvenSetDetail={onGoToSelectOvenSetDetail} onBack={onBackToSelectOvenType}/>
      case POST_BAKING_WASTE_WOOD_COUNT_STEP.SELECT_OVEN_SET:
        return <SelectOvenSet groupDate={groupDate} onSelectOvenSet={onSelectOvenSet} onBack={onBackToSelectOvenSetMenu} />
      case POST_BAKING_WASTE_WOOD_COUNT_STEP.SELECT_OVEN_SET_DETAIL:
        return <SelectOvenSetDetail groupDate={groupDate} onGoToOvenSetDetail={onGoToOvenSetDetail} onBack={onBackToSelectOvenSetMenu} />
      case POST_BAKING_WASTE_WOOD_COUNT_STEP.OVEN_SET_DETAIL:
        return <OvenSetDetail groupDate={groupDate} ovens={ovensSelect} onBack={onBackToSelectOvenSetDetail}/>
    }
    return null
  }

  const getTransaction = async () => {
    if (transaction) {
      return transaction;
    }
    const response = await POST(URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT, { 
      post_baking_group_date: groupDate, ovens: ovensSelect 
    })
    const transactionId = _.get(response, 'data.id', null)
    setTransaction(transactionId)
    return transactionId
  }

  // add confirmation before refresh
  React.useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);  

  return (
    <div>
			<Container style={{ marginTop: '7em' }}>
        <MainHeader/>
        <Header as='h2'>{t('menus.wood_menus.post_baking_waste_wood_count')}</Header>
        { renderStep() }
      </Container>
    </div>
  )
}