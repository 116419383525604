import { Alert, message, Popover } from "antd";
import React from "react";
import { ellipsisString } from "../../../utils/stringFormat";
import { message as antdMsg } from "antd";
import propTypes from "prop-types";


export default function ErrorMessage(props=defaultProps) {
  const { message, style } = props;
  const [messageApi, contextHolder] = antdMsg.useMessage()

  React.useEffect(() => {
    if (message) {
      messageApi.error(ellipsisString(message))
    }
  }, [message])

  return (
    <span>
      {contextHolder}
      { message && (
        <Popover trigger="hover" placement="bottom" content={ellipsisString(message, 1000)}>
          <Alert
            style={{ margin: "1rem 0", ...style }}
            type='error'
            showIcon
            message={ellipsisString(message, 50)}/>
        </Popover>
      )}
    </span>
  )
}

const defaultProps = {
  message: '',
  style: {},
}

ErrorMessage.propTypes = {
  message: propTypes.string,
  style: propTypes.object,
}
