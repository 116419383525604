import React from "react";
import { QC_RAW_WOOD_SETTING_TYPES } from "../../constance/dataTypes";
import { useTranslation } from "react-i18next";
import { Collapse, Form, Input, InputNumber, message, Spin, Table } from "antd";
import { ellipsisString } from "../../utils/stringFormat";
import { URL_QC } from "../../constance/urls";
import propTypes from "prop-types";
import { GET } from "../../utils/HttpClient";
import * as _ from "lodash";


const RawWoodQCDetailForm = React.forwardRef((props, ref) => {
  const {
    form,
  } = { ...defaultProps, ...props };

  const typeKeys = Object.values(QC_RAW_WOOD_SETTING_TYPES);
  const { t } = useTranslation();
  const grade = Form.useWatch('grade', form);
  const details = Form.useWatch('details', form);
  const [loading, setLoading] = React.useState(false);
  const [settings, setSettings] = React.useState([]);

  const fetchSetting = async () => {
    setLoading(true);
    try {
      const response = await GET(URL_QC.QC_RAW_WOOD_SETTING, { grade, page_size: 500 });
      setSettings(_.get(response, 'data.results', []))
    } catch (error) {
      message.open({
        type: 'error',
        content: ellipsisString(error.errorMessages, 100),
      });
    } finally {
      setLoading(false);
    }
  }

  const setFormData = () => {
    const details = form.getFieldValue('details')
    const newDetails = settings.map(result => {
      const original = details ? details.find(e => e.setting == result.id) : null
      return {
        setting: result.id,
        thin: _.get(original, 'thin', 0),
        medium: _.get(original, 'medium', 0),
        thick: _.get(original, 'thick', 0),
        edge: _.get(original, 'edge', 0),
        // for rendering
        name: result.name, type: result.type,
      }
    })
    form.setFieldValue('details', newDetails)
  }

  const poppulateData = (datas) => {
    let results = {}
    typeKeys.forEach(key => {
      let result = []
      let totalThin = 0;
      let totalMedium = 0;
      let totalThick = 0;
      let totalEdge = 0;
      const keySettings = settings.filter(setting => setting.type == key)
      if (!keySettings.length) {
        return
      }
      keySettings.forEach(setting => {
        const found = datas.find(data => data.setting == setting.id)
        totalThin += _.get(found, 'thin', 0)
        totalMedium += _.get(found, 'medium', 0)
        totalThick += _.get(found, 'thick', 0)
        totalEdge += _.get(found, 'edge', 0)
        result.push({
          name: _.get(setting, 'name', 0),
          thin: _.get(found, 'thin', 0),
          medium: _.get(found, 'medium', 0),
          thick: _.get(found, 'thick', 0),
          edge: _.get(found, 'edge', 0),
        })
      })
      result.push({
        name: t('datas.sum'),
        thin: totalThin, 
        medium: totalMedium,
        thick: totalThick,
        edge: totalEdge
      })
      results = { ...results, [key]: result }
    })
    return results
  }

  const formColumns = [
    {
      title: t('type'),
      render: ({ name }) => (
        <span>
          <Form.Item name={[name, 'name']} style={{ margin: 0 }}>
            <Input variant='borderless' />
          </Form.Item>
          <Form.Item name={[name, 'setting']} hidden/>
        </span>
      )
    },
    {
      title: t('qc_raw_woods.wood_groups.thin'),
      render: ({ name }) => (
        <span>
          <Form.Item name={[name, 'thin']} style={{ margin: 0 }} normalize={value => value ? value : 0}>
            <InputNumber placeholder='0' variant='borderless' style={{ width: '100%' }}/>
          </Form.Item>
        </span>
      )
    },
    {
      title: t('qc_raw_woods.wood_groups.medium'),
      render: ({ name }) => (
        <span>
          <Form.Item name={[name, 'medium']} style={{ margin: 0 }} normalize={value => value ? value : 0}>
            <InputNumber placeholder='0' variant='borderless' style={{ width: '100%' }}/>
          </Form.Item>
        </span>
      )
    },
    {
      title: t('qc_raw_woods.wood_groups.thick'),
      render: ({ name }) => (
        <span>
          <Form.Item name={[name, 'thick']} style={{ margin: 0 }} normalize={value => value ? value : 0}>
            <InputNumber placeholder='0' variant='borderless' style={{ width: '100%' }}/>
          </Form.Item>
        </span>
      )
    },
    {
      title: t('qc_raw_woods.wood_groups.edge'),
      render: ({ name }) => (
        <span>
          <Form.Item name={[name, 'edge']} style={{ margin: 0 }} normalize={value => value ? value : 0}>
            <InputNumber placeholder='0' variant='borderless' style={{ width: '100%' }}/>
          </Form.Item>
        </span>
      )
    },
  ]

  React.useImperativeHandle(ref, () => ({
    poppulate: poppulateData,
  }))

  React.useEffect(() => {
    if (grade) {
      fetchSetting()
    }
  }, [grade])

  React.useEffect(() => {
    if (settings.length > 0) {
      setFormData();
    }
  }, [settings])

  return (
    <Spin spinning={loading}>
      <Form.List name='details'>
        {(fields) => (
          <div style={{ textAlign: 'left' }}>
            <Collapse
              ghost
              defaultActiveKey={typeKeys.map((_, index) => index)}
              items={typeKeys.map((key, index) => {
                const datas = fields.filter(field => form.getFieldValue(['details', field.name, 'type']) == key)
                return {
                  key: index,
                  label: t(`qc_raw_woods.settings.${key}`),
                  children: datas.length > 0 ? (
                    <div>
                      <Table
                        bordered
                        pagination={false}
                        columns={formColumns}
                        dataSource={datas}
                        summary={() => (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>{t('datas.sum')}</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <InputNumber
                                style={{ width: '100%' }}
                                variant='borderless'
                                placeholder='0'
                                readOnly
                                value={details ? details.filter(detail => detail.type === key).reduce((prev, detail) => prev + Number(_.get(detail, 'thin', 0)) || 0, Number(0)) : 0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                              <InputNumber
                                style={{ width: '100%' }}
                                variant='borderless'
                                placeholder='0'
                                readOnly
                                value={details ? details.filter(detail => detail.type === key).reduce((prev, detail) => prev + Number(_.get(detail, 'medium', 0)) || 0, Number(0)) : 0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                              <InputNumber
                                style={{ width: '100%' }}
                                variant='borderless'
                                placeholder='0'
                                readOnly
                                value={details ? details.filter(detail => detail.type === key).reduce((prev, detail) => prev + Number(_.get(detail, 'thick', 0)) || 0, Number(0)) : 0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                              <InputNumber
                                style={{ width: '100%' }}
                                variant='borderless'
                                placeholder='0'
                                readOnly
                                value={details ? details.filter(detail => detail.type === key).reduce((prev, detail) => prev + Number(_.get(detail, 'edge', 0)) || 0, Number(0)) : 0}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}/>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}/>
                    </div>
                  ) : null
                }
              })}/>
          </div>
        )}
      </Form.List>
    </Spin>
  )
})

export default RawWoodQCDetailForm;

const defaultProps = {
  form: null
}

RawWoodQCDetailForm.propTypes = {
  form: propTypes.any
}