import { Flex, Form, Layout, Select, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import MainHeader from "../../components/common/MainHeader";
import Container from "../../components/common/antd/Container";
import useAntDropdownItem from "../../hooks/useAntDropdownItem";
import { URL_BARCODE, URL_EMPLOYEE, URL_REPORT } from "../../constance/urls";
import * as _ from "lodash";
import ExportReportButton from "../../components/common/antd/ExportReportButton";
import SearchSelect from "../../components/common/antd/SearchSelect";
import CustomDatePicker from "../../components/common/antd/CustomDatePicker";


export default function QCLogWoodReportSummaryView () {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const sawTimeDropdown = useAntDropdownItem({ url: URL_BARCODE.SAW_TIME, label: 'time_name' })
  const date = Form.useWatch('date', form)
  const sawTime = Form.useWatch('saw_time', form)
  const employee = Form.useWatch('employee', form)

  return (
    <Layout>
      <Layout.Header>
        <MainHeader/>
      </Layout.Header>
      <Layout.Content>
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.log_wood_summary')}</Typography.Title>
          <Form form={form} layout='horizontal' style={{ marginTop: '3rem' }} labelCol={{ span: 3, offset: 3 }}>
            <Form.Item name='date' label={t('date')} rules={[{ required: true }]}>
              <CustomDatePicker range/>
            </Form.Item>
            <Form.Item name='saw_time' label={t('log_wood_qcs.saw_time')}>
              <Select options={sawTimeDropdown.options} allowClear placeholder={`${t('select')} ${t('log_wood_qcs.saw_time')}`}/>
            </Form.Item>
            <Form.Item name='employee' label={t('log_wood_qcs.employee')}>
              <SearchSelect
                url={URL_EMPLOYEE.EMPLOYEE}
                placeholder={`${t('select')} ${t('log_wood_qcs.employee')}`}
                labelRender={(data) => `[${data.code}] ${data.name}`}/>
            </Form.Item>
          </Form>
          <Flex gap='middle' justify='center' style={{ marginTop: '2rem' }}>
            <ExportReportButton
              url={URL_REPORT.QC_LOG_WOOD_QC_TRANSACTION_SUMMARY_REPORT}
              params={{
                date_after: _.get(date, 0, null),
                date_before: _.get(date, 1, null),
                saw_time: sawTime,
                employee,
              }}
              validate={form.validateFields}/>
          </Flex>
        </Container>
      </Layout.Content>
    </Layout>
  )
}