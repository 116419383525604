import propTypes from "prop-types";
import React from "react";
import { useWindowDimensionContext } from "../../../hooks/WindowDimensionProvider";


export default function Container (props=defaultProps) {
  const {
    style,
    children,
  } = props;

  const { moreThanOrEqual } = useWindowDimensionContext();
  const isLarge = moreThanOrEqual('xl')
  
  return (
    <div style={{ margin: 'auto', maxWidth: isLarge ? '70%' : '100%', ...style }}>
      {children}
    </div>
  )
}

const defaultProps = {
  style: {},
  children: null
}

Container.propTypes = {
  style: propTypes.object,
  children: propTypes.element,
}
