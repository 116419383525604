export const defaultUserModel = {
  created: '',
	date_joined: '',
	email: '',
	display_name: '',
	is_active: '',
	last_login: '',
  groups: [],
}

export const USER_GROUP = {
  ADMIN: "admin",
  FINANCE: "finance",
  MANAGE: "manager",
  MD: "md",
  OFFICER: "officer"
}

export const USER_KEY = 'user'
export const TOKEN_KEY = 'token'
export const TOKEN_EXPIRE_DAYS = 30
