import React from "react";
import { PATH } from "../../routes/Routes";
import { useHistory } from "react-router-dom";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import SubHeader from "../../components/common/antd/SubHeader";
import SelectQCRawWoodType from "../../components/qc-raw-wood/SelectQCRawWoodType";
import { QC_RAW_WOOD_TYPES } from "../../constance/dataTypes";
import InputNormalTypeData from "../../components/qc-raw-wood/InputNormalTypeData";
import InputDefectTypeData from "../../components/qc-raw-wood/InputDefectTypeData";
import InputRawWoodQCDetail from "../../components/qc-raw-wood/InputRawWoodQCDetail";


const STEP = {
  SELECT_TYPE: 'select-type',
  INPUT_NORMAL_TYPE_DATA: 'select-normal-type-data',
  INPUT_DEFECT_TYPE_DATA: 'select-defect-type-data',
  INPUT_DETAIL: 'input-detail',
}

export default function QCRawWoodView () {
  const history = useHistory();
  const { t } = useTranslation();

  const [step, setStep] = React.useState(STEP.SELECT_TYPE);
  const [type, setType] = React.useState(null);
  const [transactionData, setTransactionData] = React.useState({})

  const onSelectType = (type) => {
    setType(type)
    if (type == QC_RAW_WOOD_TYPES.NORMAL) {
      setStep(STEP.INPUT_NORMAL_TYPE_DATA);
    } else {
      setStep(STEP.INPUT_DEFECT_TYPE_DATA);
    }
  }

  const enterWoodData = (values) => {
    setTransactionData(values)
    setStep(STEP.INPUT_DETAIL)
  }

  const onBackToSelectType = () => {
    setType(null);
    setStep(STEP.SELECT_TYPE);
  }

  const onBackToEnterData = () => {
    if (type == QC_RAW_WOOD_TYPES.NORMAL) {
      setStep(STEP.INPUT_NORMAL_TYPE_DATA);
    } else {
      setStep(STEP.INPUT_DEFECT_TYPE_DATA);
    }
  }

  const onHome = () => {
    setType(null);
    setTransactionData({});
    setStep(STEP.SELECT_TYPE);
  }

  const renderStep = () => {
    switch (step) {
      case STEP.SELECT_TYPE:
        return <SelectQCRawWoodType onSubmit={onSelectType}/>
      case STEP.INPUT_NORMAL_TYPE_DATA:
        return <InputNormalTypeData onSubmit={enterWoodData} onBack={onBackToSelectType}/>
      case STEP.INPUT_DEFECT_TYPE_DATA:
        return <InputDefectTypeData type={type} onSubmit={enterWoodData} onBack={onBackToSelectType}/>
      case STEP.INPUT_DETAIL:
        return <InputRawWoodQCDetail type={type} data={transactionData} onBack={onBackToEnterData} onHome={onHome}/>
      default: return null
    }
  }

  const onBack = () => {
    history.push(PATH.QC_MAIN);
  }

  return (
    <Layout>
      <SubHeader title={t('qc_menus.raw_wood')} onBack={onBack}/>
      <Layout.Content>
        <div style={{ padding: '1rem 1rem' }}>
          {renderStep()}
        </div>
      </Layout.Content>
    </Layout>
  )
}