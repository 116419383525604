import { Button, Collapse, Flex, Form, Layout, message, Select, Table, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { SaveOutlined } from "@ant-design/icons";
import { URL_QC } from "../../constance/urls";
import Container from "../../components/common/antd/Container";
import * as _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import SubHeader from "../../components/common/SubHeader";
import { defaultRawWoodQC, QC_RAW_WOOD_TYPES } from "../../constance/dataTypes";
import { GET, PATCH } from "../../utils/HttpClient";
import ErrorMessage from "../../components/common/antd/ErrorMessage";
import RawWoodQCDetailForm from "../../components/qc-raw-wood/RawWoodQCDetailForm";
import DefectTypeDataForm from "../../components/qc-raw-wood/DefectTypeDataForm";
import NormalTypeDataForm from "../../components/qc-raw-wood/NormalTypeDataForm";


export default function QCRawWoodReportDetailView () {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const type = Form.useWatch('type', form)

  const onBack = () => {
    history.push(PATH.QC_RAW_WOOD_REPORT)
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GET(`${URL_QC.QC_RAW_WOOD_TRANSACTION}${id}/`)
      form.setFieldsValue(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await PATCH(`${URL_QC.QC_RAW_WOOD_TRANSACTION}${id}/`, values);
      messageApi.success({ content: t('success') })
    } catch (error) {
      setErrorMessages(error.errorMessages);
      messageApi.error({ content: error.errorMessages })
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id])

  return (
    <Layout>
      <Layout.Header>
        <SubHeader/>
      </Layout.Header>
      <Layout.Content>
        {contextHolder}
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.raw_wood')}</Typography.Title>
          <ErrorMessage message={errorMessages}/>
          <Typography.Title level={5}>
            {t('qc_raw_woods.type')}: { type && t(`qc_raw_woods.types.${type}`) }
          </Typography.Title>
          <Form
            style={{ marginTop: '2rem', textAlign: 'left' }}
            form={form}
            onFinish={handleSubmit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={defaultRawWoodQC}
          >
            <Form.Item name='type' hidden/>
            { type == QC_RAW_WOOD_TYPES.NORMAL ? (
              <NormalTypeDataForm form={form}/>
            ) : (
              <DefectTypeDataForm/>
            )}
            
            <RawWoodQCDetailForm form={form}/>
            <Flex justify='center' gap='middle' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <Button size='large' type='primary' icon={<SaveOutlined/>} loading={loading} htmlType='submit'>{t('save')}</Button>
              <Button size='large' onClick={() => onBack()} loading={loading}>{t('back')}</Button>
            </Flex>
          </Form>
        </Container>
      </Layout.Content>
    </Layout>
  )
}