import { Button, Flex, Layout, theme, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import React from "react";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import QCLogWoodView from "./QCLogWoodView";
import MainHeader from "../../components/common/antd/MainHeader";


export const QC_MENUS = {
  LOG_WOOD: 'log-wood',
  RAW_WOOD: 'raw-wood',
  WET_WOOD: 'wet-wood',
  POST_BAKING_WOOD: 'post-baking-wood',
  DRY_WOOD: 'dry-wood',
}


export default function QCMainView() {
  const history = useHistory();
  const { t } = useTranslation();
  const { menu } = useParams();
  const { token: { colorPrimary } } = theme.useToken();

  const renderMenus = [
    { label: t("qc_menus.log_wood"), key: QC_MENUS.LOG_WOOD },
    { label: t("qc_menus.raw_wood"), key: QC_MENUS.RAW_WOOD, path: PATH.QC_RAW_WOOD },
    { label: t("qc_menus.wet_wood"), key: QC_MENUS.WET_WOOD, disabled: true },
    { label: t("qc_menus.post_baking_wood"), key: QC_MENUS.POST_BAKING_WOOD, disabled: true },
    { label: t("qc_menus.dry_wood"), key: QC_MENUS.DRY_WOOD, disabled: true },
  ]

  const contentMapper = [
    {
      key: QC_MENUS.LOG_WOOD,
      component: <QCLogWoodView/>
    },
  ]

  const defaultMenu = () => (
    <div style={{ marginTop: "3rem" }}>
      <Typography.Title level={3} type="secondary">{t('qc_menu')}</Typography.Title>
      <Flex vertical gap="middle">
        {renderMenus.map(menu => (
          <Button
            key={menu.key}
            type="primary"
            block
            disabled={menu.disabled}
            size="large"
            icon={<RightOutlined />}
            onClick={() => {
              history.push(
                _.get(menu, 'path', false) ? 
                _.get(menu, 'path', PATH.QC_MAIN) : 
                PATH.QC_MAIN + menu.key
              )
            }}
          >
            {menu.label}
          </Button>
        ))}
      </Flex>
    </div>
  )

  const renderContent = () => {
    const foundContent = contentMapper.find(content => content.key == menu)
    if (!foundContent) {
      return defaultMenu()
    }
    const title = _.get(renderMenus.find(e => e.key == menu), 'label', '')
    return (
      <div style={{ textAlign: "left", marginTop: "1rem" }}>
        <Button
          style={{ color: colorPrimary }}
          color='primary'
          variant="text"
          icon={<LeftOutlined/>}
          onClick={() => history.push(PATH.QC_MAIN)}
        >
          {t("back")}
        </Button>
        <Typography.Title level={4} type="secondary" style={{ textAlign: "center", marginTop: 0 }}>
          {title}
        </Typography.Title>
        {_.get(foundContent, 'component', defaultMenu())}
      </div>
    )
  }

  return (
    <Layout>
      <MainHeader/>
      <Layout.Content>
        <div style={{ padding: "0 1rem", marginBottom: '1rem' }}>
          {renderContent()}
        </div>
      </Layout.Content>
    </Layout>
  )
}
