import { Form, Layout, message, Popconfirm, Select, Space, theme, Typography } from "antd"
import React from "react"
import MainHeader from "../../components/common/MainHeader"
import { useTranslation } from "react-i18next"
import ColorButton from "../../components/common/ColorButton";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { URL_QC, URL_REPORT, URL_SCALE_LARGE } from "../../constance/urls";
import TSTable from "../../components/common/TSTable";
import Container from "../../components/common/antd/Container";
import * as _ from "lodash";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import { HUMAN_DATE_FORMAT, HUMAN_DATETIME_FORMAT } from "../../constance/strings";
import ExportReportButton from "../../components/common/antd/ExportReportButton";
import { DELETE } from "../../utils/HttpClient";
import { QC_RAW_WOOD_TYPES, WOOD_GRADE_CHOICES } from "../../constance/dataTypes";
import SearchSelect from "../../components/common/antd/SearchSelect";
import CustomDatePicker from "../../components/common/antd/CustomDatePicker";


export default function QCRawWoodReportListView () {

  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { token: { colorInfo, colorError } } = theme.useToken();
  const tableRef = React.useRef();
  const [params, setParams] = React.useState({});

  const columns = React.useMemo(() => [
    {
      title: t('log_wood_qcs.checks.date'),
      dataIndex: 'created',
      render: (value) => dayjs(value).format(HUMAN_DATETIME_FORMAT)
    },
    {
      title: t('qc_raw_woods.produce_date'),
      dataIndex: 'produce_date',
      render: (value) => dayjs(value).format(HUMAN_DATE_FORMAT)
    },
    {
      title: t('qc_raw_woods.type'),
      dataIndex: 'type',
      render: (value) => t(`qc_raw_woods.types.${value}`)
    },
    {
      title: t('qc_raw_woods.employee'),
      dataIndex: 'employee_detail',
    },
    {
      title: t('truck_transactions.company'),
      dataIndex: 'truck_company_detail',
    },
    {
      title: t('wood_imports.grade'),
      dataIndex: 'grade',
    },
    {
      title: ' ',
      dataIndex: 'id',
      render: (value) => (
        <Space>
          <ColorButton
            override={colorInfo}
            color='primary'
            variant='outlined'
            icon={<EditOutlined/>}
            onClick={() => history.push(`${PATH.QC_RAW_WOOD_REPORT}${value}/`)}
          >
            {t('edit')}
          </ColorButton>
          <Popconfirm
            title={t('confirms.delete_title')}
            description={t('confirms.delete_content')}
            okButtonProps={{ icon: <DeleteOutlined/>, content: t('delete'), danger: true }}
            cancelText={t('cancel')}
            onConfirm={() => handleDelete(value)}
          >
            <ColorButton
              override={colorError}
              color='primary'
              variant='outlined'
              icon={<DeleteOutlined/>}
            >
              {t('delete')}
            </ColorButton>
          </Popconfirm>
        </Space>
      )
    },
  ], [])

  const onSearch = async ({ date, ...value }) => {
    setParams({ ...value, date_after: _.get(date, 0, null), date_before: _.get(date, 1, null) })
  }
  
  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_QC.QC_RAW_WOOD_TRANSACTION}${id}/`)
      if (tableRef.current) {
        tableRef.current.fetch()
      }
      message.success(t('success'))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (params && tableRef.current) {
      tableRef.current.fetch()
    }
  }, [params])

  return (
    <Layout>
      <Layout.Header>
        <MainHeader/>
      </Layout.Header>
      <Layout.Content>
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.raw_wood')}</Typography.Title>
          <Form form={form} onFinish={onSearch} layout='inline' style={{ justifyContent: 'center' }}>
            <Form.Item name='date' label={t('date')}>
              <CustomDatePicker style={{ width: '100%' }} range/>
            </Form.Item>
            <Form.Item name='type' label={t('qc_raw_woods.type')}>
              <Select 
                allowClear
                placeholder={`${t('select')} ${t('qc_raw_woods.type')}`}
                options={[
                  { value: QC_RAW_WOOD_TYPES.NORMAL, label: t(`qc_raw_woods.types.${QC_RAW_WOOD_TYPES.NORMAL}`)},
                  { value: QC_RAW_WOOD_TYPES.SAW_DEFECT, label: t(`qc_raw_woods.types.${QC_RAW_WOOD_TYPES.SAW_DEFECT}`)},
                  { value: QC_RAW_WOOD_TYPES.BUY_DEFECT, label: t(`qc_raw_woods.types.${QC_RAW_WOOD_TYPES.BUY_DEFECT}`)},
                  { value: QC_RAW_WOOD_TYPES.DRY_DEFECT, label: t(`qc_raw_woods.types.${QC_RAW_WOOD_TYPES.DRY_DEFECT}`)},
                ]}/>
            </Form.Item>
            <Form.Item name='truck_company_code' label={t('truck_transactions.company')}>
              <SearchSelect
                url={URL_SCALE_LARGE.TRUCK_COMPANY}
                pagination={false}
                optionValue='code'
                placeholder={`${t('search')} ${t('truck_transactions.company')}`}
                labelRender={(data) => `[${data.code}] ${data.name}`}/>
            </Form.Item>
            <Form.Item name='grade' label={t('wood_imports.grade')}>
              <Select allowClear placeholder={`${t('select')} ${t('wood_imports.grade')}`} options={WOOD_GRADE_CHOICES}/>
            </Form.Item>
            <ColorButton type='primary' override={colorInfo} htmlType='submit' icon={<SearchOutlined/>}>{t('search')}</ColorButton>
          </Form>
          <ExportReportButton
            style={{ marginTop: '1rem' }}
            justify='end'
            url={URL_REPORT.QC_RAW_WOOD_TRANSACTION_REPORT}
            method='get'
            exportTypes={['excel']}
            typeAsParams
            params={params}/>
          <TSTable
            ref={tableRef}
            url={URL_QC.QC_RAW_WOOD_TRANSACTION}
            columns={columns}
            style={{ marginTop: '1rem' }}
            params={params} />
        </Container>
      </Layout.Content>
    </Layout>
  )
}